.container {
  gap: 16px;
  padding: 16px 0;
}

.connectionsContainer {
  border-radius: 4px;
  overflow: hidden;
}

.connectionsHeader {
  position: relative;
  width: 100%;
  padding: 12px 0 0 12px;
}

.connectionsInfo {
  gap: 4px;
}

.connectionsInfoTitle {
  font-size: 14px;
  font-weight: 600;
}

.tab {
  padding-bottom: 8px;
  gap: 8px;
  border-bottom: 2px solid;
  font-size: 14px;
}

.tabTitle {
  font-size: inherit;
  font-weight: 600;
  color: currentColor;
}

.tabTitleSubData {
  font-size: inherit;
  color: currentColor;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: currentColor;
}

.connectionsTable {
  overflow-x: auto;
  padding: 10px;
  border: 1px solid;
  border-radius: 0 0 4px 4px;
  max-height: 420px;
}

.headerTitle {
  font-size: 13px;
  font-weight: 300;
}

.connectionsTableBody {
  width: unset;
  display: grid;
  grid-template-columns: minmax(120px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) minmax(180px, 1fr);
  grid-row-gap: 4px;
  min-width: 100%;
}

.connectionsTableHeaderCell {
  padding: 6px;
  background: #f0f1f3;
}

.sortableConnectionsTableHeaderCell {
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
}

.connectionsTableHeaderCell:first-child {
  border-radius: 4px 0 0 0;
}

.connectionsTableHeaderCell:last-child {
  border-radius: 0 4px 0 0;
}

.connectionsTableHeaderCell:hover .headerSortIcon {
  opacity: 0.5;
}

.headerSortIcon {
  opacity: 0;
  transition: all 0.2s;
}

.headerSortIconActive {
  opacity: 1;
}

.connectionsTableHeaderCell:hover .headerSortIconActive {
  opacity: 1;
}

.connectionsTableCell {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ced3d9;
  padding: 6px;
  cursor: pointer;
}

.iuuStatusHeader {
  justify-content: left;
}

.iuuStatusBlock {
  justify-content: left;
  align-items: left;
}

.selectedConnection {
  background-color: #f0f1f3;
}

.connectionsTableLastRowCell {
  border-bottom: none;
}

.connectionsTableRowText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  min-width: 0;
  max-width: inherit;
}

.loadingBlock {
  min-height: 120px;
  gap: 8px;
  justify-content: center;
  width: 100%;
}

.entityTypeBlock {
  gap: 8px;
}

.entityType {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}

.relationBlock {
  gap: 4px;
  overflow: hidden;
}

.relationInnerBlock {
  gap: 8px;
}

.relationIcon {
  width: 18px;
  height: 18px;
}

.relationNode {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
}

.relationName {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 300;
}

.relationTooltip {
  max-width: 100%;
}

.chains {
  gap: 16px;
}

.disclaimer {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-align: center;
}
