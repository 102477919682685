.drawerContent {
  position: relative;
  padding: 32px;
  height: 100%;
  overflow-y: auto;
}

.drawer:global(.MuiDrawer-modal .MuiPaper-root) {
  max-width: 100%;
}

@media (min-width: 768px) {
  .drawer:global(.MuiDrawer-modal .MuiPaper-root) {
    max-width: 80%;
  }
}
