.label {
  font-size: 14px;
  padding: 4px 8px;
  background: rgba(18, 41, 69, 0.1);
  border-radius: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}

.option {
  padding: 8px;
  cursor: pointer;
  width: 100%;
}

.option:hover {
  background: #CED3D9; /* them.gray.gray100 */
}
