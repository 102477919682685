.container {
  margin: 0 auto;
  height: 100%;
  flex-wrap: nowrap;
  position: relative;
}

.networkRoot {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 2;
  background: #ffffff;
  overflow: scroll;
  transition: none;
}

.searchFiltersContainer {
  position: absolute;
  top: 0;
  max-width: 420px;
  background: white;
  z-index: 5;
  box-shadow: 0 0 3px #acacac;
  height: 100%;
  bottom: 0;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 0.2s;
}

.searchFiltersContainerOpen {
  transform: translateX(0);
}

.searchInputContainer {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
}

.searchResults {
  padding-left: 16px;
  max-height: 100%;
}

.searchResultsInner {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex-wrap: nowrap;
  margin: 0;
  max-width: 100%;
}

.drawerButton {
  display: flex;
  position: absolute;
  top: 6px;
  right: 0;
  transform: translateX(100%);
  z-index: 1;
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 0 4px 4px 0;
  box-shadow: 1px 0 1px #B8BFC7;
}

.drawerButton:hover {
  background: white;
}

.searchFiltersContainerOpen .drawerButton {
  display: none;
}

.searchPageLayout {
  display: flex;
  flex-direction: column;
}

@media (min-width: 500px) {
  .searchFiltersContainerOpen .drawerButton {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .searchFiltersContainer {
    display: flex;
    position: sticky;
    top: 0;
    bottom: unset;
    max-height: calc(100vh - 64px);
    max-width: 420px;
    background: unset;
    box-shadow: none;
    height: unset;
    width: unset;
    transform: none;
  }

  .searchResults {
    border-left: 1px solid rgba(18, 41, 69, 0.1);
    max-width: 100%;
    flex-grow: 1;
  }

  .drawerButton {
    display: none !important;
  }

  .searchInputContainer {
    width: unset;
    max-width: 700px;
    min-width: 0;
    flex-grow: 1;
    margin-left: 72px;
  }
}

