.errorBox {
  gap: 4px;
  margin-left: -32px;
  background: #F751511A;
  border: 1px solid #F75151;
  box-shadow: 0 3px 14px 0 #6262621A;
  border-radius: 4px;
}

.errorContainer {
  gap: 16px;
  padding: 8px 16px;
}

.errorIcon {
  fill: #F75151;
}

.closeIcon {
  fill: #12294580;
}

.errorText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

.errorText, .link {
  color: #122945;
}
