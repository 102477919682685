.bodyContainer {
  margin-left: 0;
  margin-top: 0;
  width: 100%;
  border-radius: 4px;
  border: 1px solid;
  border-bottom: none;
  overflow: hidden;
}

.bodyFieldValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 14px;
}

.sources {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  word-break: break-word;
}

.listItem {
  word-break: break-all;
  font-size: 14px;
}

.label,
.fieldValue {
  font-size: 14px;
}

.label {
  font-weight: 600;
}

.cell {
  position: relative;
  padding: 8px;
  padding-top: 8px !important;
  padding-left: 8px !important;
  border-left: none;
  border-bottom: 1px solid #CED3D9;
}

.cell:last-child {
  /* border-bottom: none; */
}

@media (min-width: 600px) {
  .cell:last-child {
    border-bottom: 1px solid #CED3D9;
  }

  .cell {
    border-left: 1px solid #CED3D9;
    border-bottom: 1px solid #CED3D9;
  }

  .cell:nth-child(2n + 1) {
    border-left: none;
  }
}

@media (min-width: 900px) {
  .cell:nth-child(2n + 1) {
    border-left: 1px solid #CED3D9;
  }

  .cell:nth-child(3n + 1) {
    border-left: none;
  }
}

@media (min-width: 1200px) {
  .cell:nth-child(2n + 1) {
    border-left: 1px solid #CED3D9;
  }

  .cell:nth-child(3n + 1) {
    border-left: 1px solid #CED3D9;
  }

  .cell:nth-child(4n + 1) {
    border-left: none;
  }
}
