.labelContainer {
  gap: 8px;
}

.labelColumn {
  margin-left: 10px;
}

.tableLabel {
  font-size: 14px;
  font-weight: 600;
}

.pagination {
  padding: 24px;
  font-size: 14px;
}

.paginationTotal,
.paginationPageSize {
  color: currentColor;
  font-size: inherit;
}

.paginationPageSize {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.paginationPageSizeIcon {
  width: 20px;
  fill: currentColor;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.flagNationalityContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.countryFlag {
  width: 14px;
}

h6.countryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
