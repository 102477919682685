.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  max-width: 600px;
  min-width: 0;
  width: 100%;
  gap: 24px;
  padding: 24px;
}
