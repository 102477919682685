.container {
  width: 100%;
  height: 100%;
}

.resetAll,
.apply {
  width: unset;
}

.apply {
  font-weight: 600;
}

div.textFilters {
  width: 100%;
}

.filterCards {
  padding: 24px 16px;
}

.filtersControls {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filtersControlsEndContent {
  display: flex;
  gap: 8px;
}

.box {
   border-bottom: 1px solid rgba(18, 41, 69, 0.1);
   min-height: 0;
   flex: none;
}

.filterComponentContainer {
  flex: 1;
}

.box:last-child {
   border-bottom: none;
}

.closeFilterBtn {
  display: flex;
}

.filters {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.filterCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.filterComponent {
  padding: 4px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.searchInputContainer {
  display: block;
}

div.searchInput {
  display: flex;
  padding: 8px 16px;
}

.searchInput:global(.MuiFormControl-root .MuiInputBase-input) {
  font-size: 18px;
  font-weight: 400;
}

svg.searchIcon {
  width: 18px;
  height: 18px;
}

.disabledTooltipContent, .disabledTooltipContentSubText {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.disabledTooltipContentSubText {
  font-weight: 600;
}

@media (min-width: 500px) {
  .closeFilterBtn {
    display: none;
  }
}

@media (min-width: 600px) {
  .searchInputContainer {
    display: none;
  }
}
