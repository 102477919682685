.header {
  padding: 24px;
}

.headerTitle {
  font-size: 2em;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.01em;
}

.container {
  padding: 16px 32px 32px;
  margin: 0 auto;
}

.containerFooter {
  gap: 16px;
  padding: 16px;
  margin-top: 24px;
}

@media (min-width: 768px) {
  .header {
    padding: 128px 32px 32px 32px;
  }
}
