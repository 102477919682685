.tooltipTitle {
  gap: 4px;
}

.tooltipText {
  font-size: 14px;
}

.tooltipStatusText {
  font-weight: 600;
  text-transform: lowercase;
}
