.container {
  padding: 0;
}

.adminContainer {
  position: relative;
}

.tableHeader {
  margin: 24px 0;
  gap: 8px;
  flex-wrap: wrap;
}

.searchIcon {
  width: 24px;
  height: 24px;
}

.searchInputContainer {
  gap: 0;
  min-width: 0;
  max-width: 380px;
  width: 100%;
}

.searchInput:global(.MuiTextField-root .MuiInputBase-input) {
  padding: 10px 16px;
}

.addBtn {
  padding: 10px 16px;
  gap: 4px;
  white-space: nowrap;
}

.addBtnIcon {
  width: 18px;
  height: 18px;
}

.addBtnText {
  font-size: 14px;
  font-weight: 600;
}

/* ------- */

.modalContent {
  gap: 16px;
  overflow: auto;
  max-height: 100vh;
}

.header {
  padding: 0;
  width: 100%;
}

.details {
  width: 100%;
}

.bulkCreation,
.singleCreation {
  position: relative;
}

.bulkCreation {
  width: 100%;
}

.isAdminContainer {
  margin-bottom: 16px;
}

.showMoreErrorFilesDetails {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.radioLabel:global(.MuiFormControlLabel-root .MuiTypography-root) {
  font-size: 16px;
}
