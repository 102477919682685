.backgroundBox {
  display: flex;
  padding-bottom: 2rem;
}

.imageWrapper {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.image {
  height: 100%;
}
