.errorMessage, .warningMessage {
  font-size: 14px;
  font-weight: 600;
}

.errorMessage {
  color: #F75151;
}

.warningMessage {
  color: #ffa726;
}
