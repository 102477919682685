.background {
  background-position: 100% 0;
}

.container {
  padding-top: 10rem;
}

.cardContent {
  padding: 3.5rem !important;
}

.textStyle {
  font-weight: 600;
}