.toolbar {
  justify-content: space-between;
}

.navItems {
  align-items: center;
}

.menuIconBtn {
  margin-left: 0;
}

@media (min-width: 1200px) {
  .appBar {
    height: 98px;
    justify-content: center;
  }

  .navItems {
    min-width: 0;
    max-width: 50%;
    justify-content: flex-end;
    margin: 0 32px 0 72px;
    gap: 72px;
    flex-grow: 0;
  }

  .anonymousNavItems {
    justify-content: flex-end;
  }
}
