.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  max-width: 900px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  outline: none;
}

.header {
  display: flex;
  align-items: center;
  padding: 30px;
}

.title {
  font-size: 24px;
  margin-left: 10px;
}

.titleIcon {
  width: 24px;
  height: 24px;
}

.body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: bold;
}

.disabledSectionTitle {
  color: rgba(0, 0, 0, 0.38);
}

.sectionBody {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 24px;
}

.footer {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selectAllLabel {
  font-size: 16px;
}

.innerFooter {
  width: unset;
  align-items: center;
  gap: 24px;
}

@media (min-width: 768px) {
  .sectionBody {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .sectionBody {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1024px) {
  .container {
    border-radius: 12px;
  }
}
