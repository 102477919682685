.sectionHeaderBlock {
  width: 100%;
  gap: 8px;
  margin-bottom: 8px;
}

.sectionHeader {
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
}
