.button {
  display: flex;
  align-items: center;
  border-radius: 40px;
  gap: 16px;
  padding: 4px;
  cursor: pointer;
  transition: all 0.3s;
  width: fit-content;
  margin: 0 auto;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.button:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.buttonIconContainer {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonUserIcon {
  width: 24px;
  height: 24px;
}

.buttonEndIcon {
  width: 14px;
  margin-right: 4px;
}

.profileIcon {
  width: 24px;
  height: 24px;
}

@media (min-width: 1200px) {
  .button {
    margin: 0;
  }
}
