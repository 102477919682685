.textField {
  display: none;
  border-radius: 4px;
  width: 100%;
  background-color: white;
}

.textField:global(.MuiFormControl-root .MuiInputBase-input) {
  font-size: 16px;
  font-weight: 600;
  padding: 6px 6px 6px 0;
  color: #536378;
}

.textField:global(.MuiFormControl-root .MuiInputBase-root) {
  padding: 0 2px 0 8px;
}

.clearBtn {
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.endAdornmentContainer {
  gap: 16px;
}

.searchBtn {
  height: 36px;
  width: 36px;
  padding: 0;
  min-width: 0;
}

.searchIcon {
  width: 16px;
  height: 16px;
  user-select: none;
}

@media (min-width: 600px) {
  .textField {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .textField:global(.MuiFormControl-root .MuiInputBase-input) {
    padding: 14px 14px 14px 0;
  }

  .textField:global(.MuiFormControl-root .MuiInputBase-root) {
    padding: 0 6px 0 8px;
  }

  .searchBtn {
    height: 42px;
    width: 42px;
  }

  .searchIcon {
    width: 14px;
    height: 14px;
    user-select: none;
  }
}
