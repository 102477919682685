.text {
  font-family: "Inter", sans-serif;
}

.container {
  margin-top: 0;
  margin-left: 0;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 18px;
  flex-wrap: wrap;
  padding: 0 16px;
}

.headerStart {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 0;
}

.headerEnd {
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;
}

.nodeTitle {
  font-size: 16px;
  width: 130px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  border-color: rgba(0, 0, 0, 0.22);
  border-width: 3px;
  padding: 5px;
  opacity: 1;
  color: #fff;
  transform: translateY(-5px);
  white-space: nowrap;
  border-radius: 4px;
  margin: 20px 0 0 0;
  user-select: none;
}

.backBtnText,
.networksTitle,
.displayTypeLabel {
  font-size: 18px;
  font-weight: 600;
}

.displayTypeLabel {
  display: none;
  white-space: nowrap;
}

.backBtnIcon {
  width: 12px;
  height: 12px;
}

.layoutSelect:global(.MuiInputBase-root .MuiSelect-select) {
  padding: 8px;
}

.layoutSelect:global(.MuiInputBase-root .MuiSvgIcon-root) {
  position: relative;
}

.networkGraphWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 80vh;
  width: 100%;
  z-index: 1;
}

.networkGraphContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.graph {
  flex: 3;
  min-width: 0;
}

.graphCanvasContainer {
  width: 100%;
  height: 100%;
}

h6.networksTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
  line-height: 1;
}

div.backBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 15px 0;
  padding-top: 0 !important;
  flex: none;
  gap: 8px;
}

.backBtnText {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

.controlsButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  right: 28px;
  bottom: 48px;
  z-index: 2;
}

.controlButtonIcon {
  fill: #122a47;
}

.controlButton {
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 4px #f0f1f3;
  background: #f0f1f3;
  transition: all 0.3s;
}

.controlButton:hover {
  background: #b8bfc7;
}

.controlButton:active {
  background: #9ba5b1;
}

.helperText {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  padding: 8px;
  border-radius: 4px;
  user-select: none;
}

.downloadButton {
  padding: 4px;
  border-radius: 4px;
  min-width: 0;
  border: 1px solid;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.downloadButtonIcon {
  width: 18px;
  height: 18px;
}

.downloadButton:active {
  background: rgba(18, 41, 69, 0.06);
}

.disclaimerText {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
}

.disclaimerIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: -10px;
}

@media(hover: hover) {
  .downloadButton:hover {
    background: rgba(18, 41, 69, 0.06);
  }
}

@media (min-width: 480px) {
  .header {
    flex-wrap: nowrap;
  }
}

@media (min-width: 600px) {
  .displayTypeLabel {
    display: block;
  }
}

@media (min-width: 768px) {
  .networkGraphWrapper {
    width: calc(100% - 300px);
  }
}
