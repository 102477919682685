.badgeBox {
  background-color: #122945;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 20px;
  height: 20px;
  font-weight: 500;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0;
  border-radius: 10px;
  font-size: 0.75rem;
}