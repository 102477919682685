.files {
  margin: 16px 0;
  gap: 8px;
}

.fileContainer {
  padding: 4px;
  border-radius: 4px;
}

.filename {
  font-size: 16px;
  font-weight: 600;
}

.fileDeleteIcon {
  width: 12px;
  height: 12px;
}
