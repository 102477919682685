.container {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
}

.labelContainer {
  gap: 16px;
  flex: 1;
  margin-left: 16px;
  min-width: 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  align-self: end;
  flex: none;
}

.labelBlock {
  min-width: 0;
}

.icon {
  width: 32px;
  height: 31px;
}

.closeIcon,
.closeIcon:hover {
  background-color: #ffffff;
  box-shadow: 1px 0 2px #cdcdcd8c;
}

.label {
  font-size: 24px;
  font-weight: 600;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.entityType {
  font-size: 14px;
  font-weight: 600;
  padding: 4px;
  border-radius: 4px;
  display: inline-block;
  text-transform: capitalize;
}

.headerEndContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 32px;
  align-items: flex-end;
}

.viewNetworkGraphBlock {
  cursor: pointer;
  gap: 10px;
}

.viewNetworkGraph {
  font-size: 16px;
  text-decoration: underline;
  white-space: nowrap;
}

.viewNetworkGraphIcon {
  margin-left: 8px;
}

.viewNetworkGraphIcon path {
  fill: #F75151;
}

.source {
  font-size: 14px;
  text-align: end;
}

.sourceLink {
  color: inherit;
}

.downloadButton {
  padding: 4px;
  border-radius: 4px;
  min-width: 0;
  border: 1px solid;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.downloadButtonIcon {
  width: 16px;
  height: 16px;
}

.downloadButton:active {
  background: rgba(18, 41, 69, 0.06);
}

@media(hover: hover) {
  .downloadButton:hover {
    background: rgba(18, 41, 69, 0.06);
  }
}

@media (min-width: 768px) {
  .container {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .labelContainer {
    flex: unset;
    margin-left: 0;
  }

  .closeIcon {
    position: fixed;
    left: 20%;
    transform: translateX(-50%);
  }

  .headerEndContent {
    width: unset;
    margin-top: unset;
  }
}
