.mmsiButton {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 4px;
}

.mmsiIcon {
  width: 12px;
  height: 12px;
}

.popoverContent {
  padding: 8px;
}

.benchmarkItem {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;
}

.benchmarkItemText {
  color: currentColor;
}

.disabledBenchmarkItem {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.8;
  filter: contrast(0.5);
}
