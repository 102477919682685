.labelContainer {
  gap: 8px;
}

.tableLabel {
  font-size: 14px;
  font-weight: 600;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
