.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: rgba(186, 186, 186, 0.3);
  z-index: 5;
}

.hideLoader {
  opacity: 0;
  z-index: -1;
}

.loaderAbsolute {
  position: absolute;
}
