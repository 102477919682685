.dragAndDropContainer {
  cursor: pointer;
  height: 200px;
  border: 1px dashed;
  border-color: #CED3D9;
  border-radius: 4px;
  margin-top: 16px;
}

.dragOver {
  border: 1px solid;
}

.dragAndDropTitleContainer {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.dragAndDropIcon {
  margin-bottom: 16px;
}

.dragAndDropTitle {
  font-size: 16px;
  font-weight: 600;
}

.dragAndDropSubTitle {
  font-size: 14px;
  font-weight: 400;
}

.tooltipText {
  font-size: 12px;
  font-weight: 600;
}
