:root {
  --node-tooltip-text: none;
  --node-tooltip-color: #fff;
  --node-tooltip-background: #122945;
}

.labelWrapper {
  position: relative;
}

.labelIcon {
  position: absolute;
  top: -4px;
  right: 0;
  transform: translate(50%, -50%);
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  outline: 1px solid #ffffff;
  width: 16px;
  height: 16px;
}

p.label {
  position: relative;
  cursor: pointer;
  overflow: visible;
}

.label::after {
  content: var(--node-tooltip-text);
  position: absolute;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  top: -4px;
  opacity: 0;
  color: var(--node-tooltip-color);
  background-color: var(--node-tooltip-background);
  left: 50%;
  transform: translate(-50%, -100%);
  box-shadow: 0 0 4px #000;
  max-width: 240px;
  word-break: break-word;
  white-space: initial;
  justify-content: center;
  width: max-content;
}

.label:hover::after {
  animation-name: label-tooltip;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
  animation-duration: 0.2s;
}

@keyframes label-tooltip {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
