.loginForm {
  width: 100%;
}

.email {
  margin-bottom: 16px;
}

.currentEmail {
  text-decoration: underline;
}

.loginLabel {
  font-size: 18px;
  font-weight: 600;
}

.modalBody {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 0;
  max-width: 480px;
  width: 100%;
  padding: 32px;
  border-radius: 3px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.closeBtn {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.successIcon {
  width: 64px;
  height: 64px;
}

.modalText {
  font-weight: 600;
}
