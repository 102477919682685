div.searchResultsTitleContainer {
  padding: 16px !important; /* shadow mui styles */
  margin-left: 32px;
}

.searchResultsTitle {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
}

.searchResultsTableWrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 100%;
}

.searchResultsTableGrid {
  overflow-y: auto;
  padding: 0 !important; /* shadow mui styles */
}

@media (min-width: 1200px) {
  div.searchResultsTitleContainer {
    margin-left: 0;
  }
}
