.sortIcon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}

.parentDataGridContainer {
  min-width: 200px;
  min-height: 500px;
}
