.box {
  gap: 8px;
}

.label {
  font-size: 14px;
  padding: 0 4px;
}

.select {
  width: 100%;
}

.select:global(.MuiInputBase-root .MuiSelect-select){
  padding: 4px 14px;
  height: 40px;
  align-items: center;
  display: flex;
}

.placeholder {
  top: 50%;
  transform: translate(0, -50%);
  padding: 8px 14px;
}

.hidePlaceholder {
  display: none;
}

.placeholderText {
  font-size: 14px;
  color: #b7bec6;
}
