.container {
  padding: 0;
  max-width: unset;
}

.tables {
  max-width: 1200px;
  margin: 0 auto;
}

.tables:global(.MuiGrid-item) {
  padding: 32px;
}

.hiddenTableStyles {
  /*
    Yes it's look like very strange,
    but this need to save Favorites or Recently Viewed last state tables,
    even if the one of these table are closed.

    Maybe better to use just display: none, but in this case MUI DataGrid raise the error:
    'The parent of the grid has an empty width'
  */
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 1px;
  z-index: -1;
  overflow: hidden;
}

.resultsContainer {
  position: relative;
}

div.accessDenied {
  z-index: 5;
  background: rgba(186, 186, 186, 0.8);
}

.tabs {
  margin-left: 20px;
}

div.tabItem {
  font-size: 14px;
  font-weight: 600;
  min-width: 124px;
}

@media (min-width: 768px) {
  .tables:global(.MuiGrid-item) {
    padding: 32px 64px;
  }
}
