.accountInfoContainer {
  position: relative;
}

.usernameInputs {
  gap: 8px;
}

.errorMessage {
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: -16px;
  padding: 4px;
  color: #F75151;
}

.userField {
  margin-bottom: 16px;
}

.usernameInput {
  flex: 1;
  min-width: 200px;
}

.lastField {
  margin-bottom: 24px;
}

.profileFooter {
  gap: 16px;
}
