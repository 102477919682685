.tabs {
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  border-left: none;
  transition: all 0.3s;
}

.tab:first-child {
  border-left: 1px solid currentColor;
  border-radius: 4px 0 0 4px;
}

.tab:last-child {
  border-radius: 0 4px 4px 0;
}

.tab:hover {
  opacity: 0.8;
}
