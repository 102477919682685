.networkButtonContainer {
  cursor: pointer;
}

.networkButton {
  cursor: pointer;
  margin-top: -2.5rem !important;
  padding: 8px;
}

.networkLink {
  margin-right: 4px;
  width: 24px;
  height: 24px;
}
