.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  background: rgba(186, 186, 186, 0.3);
  z-index: 3;
}

.absolute {
  position: absolute;
}

.title {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.icon {
  width: 64px;
  height: 64px;
}
