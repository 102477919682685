.card {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.3s;
  padding: 10px;
  cursor: pointer;
}

.card:hover,
.card:active {
  border: 2px solid #122945;
}

.disabledCard:hover,
.disabledCard:active {
  border: 2px solid #ced3d9;
}

.label {
  color: currentColor;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
}

.image {
  fill: currentColor;
}

.filtersCount {
  padding: 0;
  margin: 0;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  user-select: none;
}

.infoBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: all 0.3s;
}

.infoBadge:has(.filtersCount) {
  background: #f75151;
}

.infoBadge:has(.filtersCount):before {
  transition: all 0.3s;
  position: absolute;
  content: "\00d7";
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: #f75151;
  line-height: 10px;
  border-radius: 50%;
  font-family: sans-serif;
  font-size: 12px;
  opacity: 0;
  font-weight: 600;
}

.infoBadge:has(.filtersCount):hover:before {
  opacity: 1;
}
