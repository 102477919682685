.descriptionContainer {
  gap: 32px;
  margin-bottom: 16px;
}

.accordion {
  background: transparent;
  margin: 0 !important; /* shadow mui styles */
  box-shadow: none;
  border-bottom: 2px solid;
}

.summary:global(.MuiAccordionSummary-root .MuiAccordionSummary-content) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.summaryIcon {
  width: 16px;
  height: 16px;
  transition: all 0.2s;
  transform: rotate(0deg);
  transform-origin: center;
}

.summaryIconExpanded {
  transform: rotate(90deg);
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.description {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}

.descriptionLink {
  text-decoration: underline;
}

