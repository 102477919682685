.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.entityTypeImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: unset;
}

.displayLabel {
  display: inline;
  font-size: inherit;
}
