.chain {
  gap: 10px;
  flex-wrap: wrap;
}

.member,
.chainMemberWrapper {
  cursor: pointer;
  gap: 8px;
}

.divider {
  width: 24px;
  height: 1px;
  display: block;
  border-radius: 50%;
  flex: none;
}

.titleBlock {
  gap: 4px;
}

.chainEdge,
.title {
  font-size: 12px;
  line-height: 1;
  margin: 0;
}

.title {
  font-weight: 600;
  min-width: 0;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chainEdge {
  font-weight: 300;
  white-space: nowrap;
}
