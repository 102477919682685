.cellText {
  font-size: 14px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filled {
  border-radius: 4px;
  padding: 4px;
}
