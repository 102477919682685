.wrapper {
  padding: 24px;
  border-top: 1px solid #f4f4f4;
}

.linksStack {
  display: flex;
  flex-direction: column;
  gap: 8px
}

.additionalInfo {
  margin-top: 10px;
}

.text, .link {
  font-size: 14px;
}

.link {
  font-weight: 700;
}

@media (min-width: 600px) {
  .linksStack {
    flex-direction: row;
    gap: 32px;
  }

  .innerContainer {
    padding: 0 48px;
  }
}

@media (min-width: 900px) {
  .linksStack {
    justify-content: flex-end;
  }

  .additionalInfo {
    text-align: end;
  }
}
