.container {
  flex-wrap: nowrap;
  height: 100vh;
}

.backgroundContainer {
  max-width: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
  flex: 1;
  transition: all 0.3s;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  height: 100%;
}

.authContainer {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  padding: 16px 32px;
}

@media (min-width: 768px) {
  .backgroundContainer {
    max-width: 40%;
  }

  .authContainer {
    padding: 32px 64px;
  }
}

