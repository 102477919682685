.profileContainer {
  padding: 8px;
  min-height: 80vh;
  display: block;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .profileContainer {
    padding: 32px;
  }
}
