.scheme {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: relative;
  flex: none;
}

.schemeImage,
.hoverImage,
.closeImage {
  width: 14px;
  height: 14px;
  fill: #122945;
}

.hoverImage,
.closeImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: all 0.2s;
}

.scheme:hover .hoverImage {
  opacity: 1;
}

.scheme:hover .schemeImage {
  opacity: 0;
}

.active .hoverImage {
  opacity: 1;
}

.active:hover .closeImage {
  opacity: 1;
}
