.fileIndexContainer {
  margin-left: 8px;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.fileIndexTitle {
  font-size: 16px;
  font-weight: 600;
}
