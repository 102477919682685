.box {
  gap: 8px;
}

.boxDisabled {
  cursor: not-allowed;
}

.label {
  font-size: 14px;
  padding: 0 4px;
}

.textField {
  border-right: 4px;
  width: 100%;
}

.textField:global(.MuiFormControl-root .MuiInputBase-input) {
  padding: 14px 16px;
  font-size: 14px;
  color: #536478;
}

.textFieldDisabled:global(.MuiFormControl-root .MuiInputBase-input) {
  cursor: not-allowed;
}

.passwordEndAdornment {
  cursor: pointer;
}

.passwordEndAdornmentIcon {
  width: 22px;
  height: 22px;
  fill: #536478;
}

.autocomplete:global(.MuiAutocomplete-root .MuiInputBase-root) {
  padding: 0;
}
