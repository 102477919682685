.callbackTitle {
  font-weight: 600;
  font-size: 18px;
}

.authErrorBlock {
  gap: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginButton {
  width: fit-content;
  font-weight: 600;
}
