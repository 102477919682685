.box {
  padding: 4px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.countryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  width: 100%;
}

.countryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #122945;
}

.countryCount {
  font-size: 14px;
  font-weight: 600;
  padding: 4px;
  border-radius: 4px;
}

.filterOption:global(.MuiFormGroup-root .MuiFormControlLabel-label) {
  font-size: 14px;
  color: #122945;
}
